<script>
  import { twMerge } from 'tailwind-merge'
  import { colors, baseSize, sizes, baseStyle } from './base-settings.js'

  export let size = baseSize
  export let role = 'img'
  export let color = 'currentColor'
  export let checkmarkColor = null

  export let ariaLabel = 'checkmark filled'

  $: fillColor = colors[color] || color
  $: checkmarkColorCode = colors[checkmarkColor] || colors.white
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  fill={fillColor}
  {...$$restProps}
  class={twMerge(baseStyle, sizes[size ?? 'md'], $$props.class)}
  {role}
  tabindex="-1"
  aria-label={ariaLabel}
  viewBox="0 0 24 24"
  on:click
  on:keydown
  on:keyup
  on:focus
  on:blur
  on:mouseenter
  on:mouseleave
  on:mouseover
  on:mouseout
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <path
    d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
  />
  <path
    d="M10.3151 14.0342C12.1665 12.1388 13.873 10.3945 15.5858 8.65016C15.8629 8.36678 16.1211 7.95118 16.4549 7.87561C16.9586 7.75596 17.7898 7.67409 17.9977 7.95117C18.281 8.32271 18.3755 9.28616 18.1047 9.57583C15.8629 11.9625 13.5267 14.2609 11.1841 16.5531C10.6299 17.0947 9.9687 17.0632 9.39566 16.4965C8.29994 15.4134 7.20423 14.3303 6.1337 13.2283C5.56065 12.6426 5.43469 11.9499 6.077 11.3516C6.71302 10.7597 7.38055 10.9297 7.9473 11.528C8.68408 12.2962 9.40823 13.0771 10.3213 14.0405L10.3151 14.0342Z"
    fill={checkmarkColor
      ? checkmarkColorCode
      : color === 'white'
        ? colors.black
        : colors.white}
  />
</svg>
